"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import MobileNavigationBar from "./MobileNavigationBar";
import { useRouter } from 'next/navigation';

interface NavigationBarProps {
  isDevComplete: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    return <MobileNavigationBar />;
  }

  return (
    <nav className="flex items-center p-6 bg-transparent backdrop-blur-sm z-50 sticky top-0">
      <div className="flex-1">
        <Link href="/" className="transition-transform hover:scale-105 duration-300 block">
          <Image src="/logo.png" alt="Melo Logo" width={120} height={40} priority className="drop-shadow-lg" />
        </Link>
      </div>
      <div className="flex-1 flex justify-center">
        <div className="flex items-center space-x-9 border border-white/30 rounded-full px-8 py-3.5 text-lg relative 
                        backdrop-blur-md bg-white/5 shadow-[0_0_15px_rgba(255,255,255,0.1)]">
          <Link 
            href={isDevComplete && !isSignedIn ? "/sign-up" : "#"} 
            className="text-white/90 hover:text-white transition-all duration-300 relative group"
          >
            <span>product</span>
            <span className="absolute bottom-0 left-0 w-0 h-[1px] bg-white group-hover:w-full transition-all duration-300" />
          </Link>
          <Link href="/pages/about" className="text-white/90 hover:text-white transition-all duration-300 relative group">
            <span>company</span>
            <span className="absolute bottom-0 left-0 w-0 h-[1px] bg-white group-hover:w-full transition-all duration-300" />
          </Link>
          <Link href="/pages/contact" className="text-white/90 hover:text-white transition-all duration-300 relative group">
            <span>contact</span>
            <span className="absolute bottom-0 left-0 w-0 h-[1px] bg-white group-hover:w-full transition-all duration-300" />
          </Link>
        </div>
      </div>
      <div className="flex-1 flex justify-end items-center space-x-6">
        {isSignedIn ? (
          <>
            <Link href="/conversation">
              <Button 
                variant="outline" 
                className="rounded-full text-white/90 bg-white/5 border-white/30 
                          hover:bg-white/15 hover:border-white/50 hover:text-white px-8 py-2.5 text-base 
                          transition-all duration-300 shadow-[0_0_15px_rgba(255,255,255,0.1)]
                          backdrop-blur-md"
              >
                <span className="relative">dashboard</span>
              </Button>
            </Link>
            <div className="transform hover:scale-105 transition-transform duration-300">
              <UserButton 
                afterSignOutUrl="/" 
                appearance={{
                  elements: {
                    avatarBox: "bg-white/5 rounded-lg"
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            {isDevComplete ? (
              <>
                <Link href="/sign-up">
                  <Button 
                    variant="outline" 
                    className="rounded-full text-white/90 bg-white/5 border-white/30 
                              hover:bg-white/15 hover:border-white/50 hover:text-white px-8 py-2.5 text-base 
                              transition-all duration-300 shadow-[0_0_15px_rgba(255,255,255,0.1)]
                              backdrop-blur-md"
                  >
                    <span className="relative">sign up</span>
                  </Button>
                </Link>
                <Link href="/sign-in">
                  <Button 
                    variant="outline" 
                    className="rounded-full text-white/90 bg-white/5 border-white/30 
                              hover:bg-white/15 hover:border-white/50 hover:text-white px-8 py-2.5 text-base 
                              transition-all duration-300 shadow-[0_0_15px_rgba(255,255,255,0.1)]
                              backdrop-blur-md"
                  >
                    <span className="relative">sign in</span>
                  </Button>
                </Link>
              </>
            ) : (
              <Button 
                disabled 
                className="bg-white/10 text-white/70 border border-white/20 rounded-full px-8 py-2.5 
                          text-base backdrop-blur-md shadow-[0_0_15px_rgba(255,255,255,0.05)]"
              >
                <span className="relative">Coming Soon :)</span>
              </Button>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
